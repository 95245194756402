<template>
    <div style="width: fit-content; background-color: white;">
        <div>
            <b-sidebar id="sidebar-help" title="Help" shadow right>
                <div style="padding:1rem">
                    <h2 class="pb-5" style="font-weight: 600">Calculator</h2>
                    <h4 class="pb-3">In this section you can explore how a trade performed statistically to help you determine its risk and reward.This program creates a table showing the results of putting on the same trade every year at the same time.</h4>
                    <h3 class="pt-5">Table</h3>
                    <h4><b>Open, Close: </b> In any given year, if the selected open date is not traded, the program moves forward in time up to four days looking for an open. If the close date is not traded, the program moves backward in time up to four days looking for a close.</h4>
                    <h4><b>Change: </b>difference between the Open value and the Close value.</h4>
                    <h4><b>Equity Change: </b>difference in dollar value between the Open date and the close date (equity change = change x unit move)</h4>
                    <h4><b>Days: </b>difference in days between the Open date and the close date.</h4>
                    <h4><b>Average profit per day: </b>equity change / days</h4>
                    <h4><b>Maximum adverse excursion: </b>date and value of the greatest loss that occurred between the during the trade.</h4>
                    <h4><b>Maximum profitable excursion: </b>date and value of the greatest profit that occurred between the during the trade.</h4>
                    <hr>
                    <h4><b>In the last row of the table we find:</b></h4>
                    <ul>
                        <li><h4> - Number of years examined,</h4></li>
                        <li><h4> - Percent profitable: ratio of the number of profitable trades to the total number of trades,</h4></li>
                        <li><h4> - Averages of the columns</h4></li>
                    </ul>
                    <hr>
                    <h4>Need support? Visit the Support page or <a href="/support">click here</a></h4>
                </div>
            </b-sidebar>
        </div>

        <table v-if="namespace !== 'undefined'" border=0 style="text-align: left; margin: 2px 0 -2px 3px; vertical-align: bottom;"> 
            <tr style="text-align: left; margin-bottom: 0px; vertical-align: bottom;">
                <td style="vertical-align: bottom; padding: 0px 0px 0px 5px; background-color: white; width: 100%">

            <b-container fluid style="width: auto; margin-top: 0px; background-color: white; margin-left: -20px;">
                <b-row align-h="start" align-v="end" style="background-color: white; padding: 1px" class="row no-gutters">
                
                    <b-col md="auto" style="margin: 0 2px 1px 2px; padding: 1px">  
                         <commodity-box v-bind:namespace="namespace">
                                    <template v-slot:contract-aligner>
                                        <contract-aligner-selector v-bind:namespace="namespace" v-bind:title="'Aligner'"></contract-aligner-selector>     
                                    </template>              
                                </commodity-box>
                    </b-col>

                    <b-col style="margin: 0px -15px 2px 0px; width: 315px">
                        <b-button-group style="border: 1px solid gray; padding: 2px; white-space: nowrap; background-color: #e7edf5; border-radius: 3px"> 
                            <span style="padding-top: 7px; font-weight: bold">charts per row:&nbsp;</span>    
                            <b-form-select v-model="chartsPerRow" :options="chartsPerRowOptions" size="sm" style="border: 1px solid gray;
                                 margin: 0 2px 0 1px"></b-form-select>
                    </b-button-group>
                    </b-col>
          
                  </b-row>
            </b-container>
            </td>
            </tr>
        </table>                 
    </div>
</template>

<script>

    import commodityBox from '@/components/commodity-box';
    import contractAlignerSelector from '@/components/contract-aligner-selector';

    export default {
        components: {
            commodityBox,
            contractAlignerSelector
        },
        mounted() {
          //  console.log("history-input.vue mounted() starting. this.namespace=", this.namespace);

            let observer = new ResizeObserver(entries => {
               // console.log("ResizeObserver() starting. this.namespace=", this.namespace, " activeModuleName=", this.$store.getters['activeModuleName']);
                if (this.$store.getters['activeModuleName'] === this.namespace) {
                   // console.log("checking input height.");
                    for (let entry of entries) {
                        // console.log("entry=", entry);
                        // Now do something with the resized element
                       // console.log("entry.contentRect.height=", entry.contentRect.height);
                       this.$store.commit(this.namespace + '/browserSideOnly/setInputDivHeight', entry.contentRect.height);
                       this.$store.commit(this.namespace + '/browserSideOnly/setInputDivWidth', entry.contentRect.width);
                    }
                }
            });
            let element = document.querySelector('#' + this.namespace + 'InputDiv');
           // console.log("element=", element);
            observer.observe(element);
        },
        props: ['namespace'],
        data() {
            return {
                chartsPerRowOptions: Array(4).fill().map((x, i) => ({value: (i + 1), text: (i + 1)})),
            }
        },
        computed: {
            chartsPerRow: {
                get() {
                    // console.log("chartsPerRow get:");
                    // console.log("this.$store.state[this.namespace]=", this.$store.state[this.namespace]);
                    return this.$store.state[this.namespace].chartParameters.chartsPerRow;
                },
                set(chartsPerRow) {
                    this.$store.commit(this.namespace + "/chartParameters/setChartsPerRow", parseInt(chartsPerRow));
                    this.$store.commit(this.namespace + "/chartParameters/setPage", 1);
                    // updateChart();
                }
            }
        }
    };

</script>
